import request from '@/utils/request'

export function submitOrder(data) {
    console.log(data)
    return request({
        url: '/api/order/submitOrder',
        method: 'post',
        data
    })
}
export function queryWaitTruncationOrder(data) {
    console.log(data)
    return request({
        url: '/api/order/queryWaitTruncationOrder',
        method: 'post',
        data
    })
}
export function queryAlreadyTruncationOrder(data) {
    console.log(data)
    return request({
        url: '/api/order/queryAlreadyTruncationOrder',
        method: 'post',
        data
    })
}

export function updateTruncation(data) {
    console.log(data)
    return request({
        url: '/api/order/updateTruncation',
        method: 'post',
        data
    })
}

// 查询订单
export function queryOrderList(data) {
    return request({
        url: '/api/order/queryOrderList',
        method: 'post',
        data
    })
}

export function queryOrderListDetail(data) {
    return request({
        url: '/api/order/queryOrderListDetail',
        method: 'post',
        data
    })
}
export default { submitOrder, queryWaitTruncationOrder,updateTruncation,queryAlreadyTruncationOrder,queryOrderList,queryOrderListDetail }
